<template>
  <v-navigation-drawer
    v-model="drawer"
    @input="onHandleInput"
    fixed
    app
    clipped
    width="250"
    mobile-breakpoint="1440"
    color="grey lighten-4"
    disable-resize-watcher
    class="elevation-2"
  >
    <v-list dense flat>
      <v-list-group
        v-for="item in items"
        v-if="item.items"
        :key="item.title"
        color="primary"
        :ripple="false"
        value="true"
      >
        <template v-slot:activator>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </template>
        <v-list-item v-for="(menu, i) in item.items" :key="i" :ripple="false" :to="menu.to" link>
          <v-list-item-avatar size="32">
            <v-icon size="large">{{ menu.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="menu.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item v-else color="primary" :ripple="false" link :to="item.to">
        <v-list-item-avatar size="32">
          <v-icon size="large">{{ item.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description app-sidebar Component Logic
   * @date 28.07.2020
   */
  export default {
    name: "app-sidebar",
    props: {
      items: {
        type: Array,
        required: true,
        default: () => []
      }
    },
    data: () => ({
      drawer: true
    }),

    created() {
      this.$eventBus.$on("open:sidebar", state => {
        this.drawer = state;
      });
    },
    methods: {
      onHandleInput(e) {
        if (!e) this.$eventBus.$emit("close:sidebar", false);
      }
    }
  };
</script>

<style scoped></style>
